@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playball&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

$enable-gradient: true;
$enable-negative-margins: true;

@import "./variables";
@import "~bootstrap/scss/bootstrap.scss";

html {
  font-size: 14px;
}

.angled_container {
  background-color: #e0e0e0;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.angled_container:before,
.angled_container:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.angled_container:before {
  background-color: rgba(135, 202, 202, 0.5);
  transform: skewY(60deg);
  transform-origin: left top;
  z-index: -1;
}
.angled_container:after {
  background-color: rgba(135, 202, 202, 0.5);
  transform: skewY(-60deg);
  transform-origin: left top;
  z-index: -1;
}

body {
  background-color: $pixi-central-background;
  font-family: $primary-font-family, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card.dash-card {
  border: none;
}

.card .card-title {
  font-weight: bold;
}

.dash-shadow {
  box-shadow: 1px 1px 2px #cccccc;
}

.logo-text-login {
  color: #fff;
  line-height: 0.9;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: $primary-font-family;
}

.error {
  border: none;
  box-shadow: 1px 1px 4px red;
}

.pointer {
  cursor: pointer;
}

.all-scroll {
  cursor: all-scroll;
}

.domain-alert {
  background: url("../images/bg-moved-2.jpg");
  font-size: 1rem;
  padding: 1rem 0.5rem;
  &.add-margin {
    margin-top: 0;
  }
  .domain-alert-text {
    font-family: $pixi-secondary-font-family;
    font-size: 2rem;
  }
  span {
    font-weight: bold;
  }
}

@include media-breakpoint-up(lg) {
  .domain-alert {
    padding: 1rem;
    &.add-margin {
      margin-top: $navbar-height;
    }
    font-size: 1.5rem;
    .domain-alert-text {
      font-size: 2.5rem;
    }
  }
}

.text-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.jumbotron {
  padding: 4rem 2rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: $pixi-radius;
}

.hoverable-btn {
  &:hover,
  &:active {
    filter: brightness(96%);
  }
}

// Temporarily retaining this. To be removed on pre signin page changes
// .landing-page-btn {
//   background-color: $pixi-pink !important;
//   border-color: $pixi-pink !important;
//   color: #fff !important;
//   @extend .hoverable-btn;
// }

// To be removed with pixi integration changes
.btn-primary {
  color: #fff !important;
}

.form-group {
  margin-bottom: 1rem;
}

.hidden-arrow-input-group {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.hidden-y-scroll {
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  //Firefox
  scrollbar-width: none;
}
